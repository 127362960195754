import React from "react";
import { default as HonyAndDan } from './danandhony/App.js';
import { default as AlfredAndHilary } from './alfredandhilary/App.js';
import { default as Home } from './App.js';

function Router() {
    switch(window.location.host.split('.')[0]) {
        case 'honyanddan':
            return (<HonyAndDan />);
        case 'alfredandhilary':
            return (<AlfredAndHilary />);
        default:
            return (<HonyAndDan />);
    }
}

export default Router;